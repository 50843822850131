'use strict';

var Point = require('@mapbox/point-geometry');
var VectorTileFeature = require('@mapbox/vector-tile').VectorTileFeature;
module.exports = GeoJSONWrapper;

// conform to vectortile api
function GeoJSONWrapper(features, options) {
  this.options = options || {};
  this.features = features;
  this.length = features.length;
}
GeoJSONWrapper.prototype.feature = function (i) {
  return new FeatureWrapper(this.features[i], this.options.extent);
};
function FeatureWrapper(feature, extent) {
  this.id = typeof feature.id === 'number' ? feature.id : undefined;
  this.type = feature.type;
  this.rawGeometry = feature.type === 1 ? [feature.geometry] : feature.geometry;
  this.properties = feature.tags;
  this.extent = extent || 4096;
}
FeatureWrapper.prototype.loadGeometry = function () {
  var rings = this.rawGeometry;
  this.geometry = [];
  for (var i = 0; i < rings.length; i++) {
    var ring = rings[i];
    var newRing = [];
    for (var j = 0; j < ring.length; j++) {
      newRing.push(new Point(ring[j][0], ring[j][1]));
    }
    this.geometry.push(newRing);
  }
  return this.geometry;
};
FeatureWrapper.prototype.bbox = function () {
  if (!this.geometry) this.loadGeometry();
  var rings = this.geometry;
  var x1 = Infinity;
  var x2 = -Infinity;
  var y1 = Infinity;
  var y2 = -Infinity;
  for (var i = 0; i < rings.length; i++) {
    var ring = rings[i];
    for (var j = 0; j < ring.length; j++) {
      var coord = ring[j];
      x1 = Math.min(x1, coord.x);
      x2 = Math.max(x2, coord.x);
      y1 = Math.min(y1, coord.y);
      y2 = Math.max(y2, coord.y);
    }
  }
  return [x1, y1, x2, y2];
};
FeatureWrapper.prototype.toGeoJSON = VectorTileFeature.prototype.toGeoJSON;