<fly-modal
  [title]="title()"
  [modalType]="ModalType.Alert"
  [displayCloseButton]="false"
  [confirmButtonText]="'OK'"
  (confirm)="modal().hide()">
  <div class="modal-content">
    We are currently working on making this feature available.
    <br />
    Please revisit this page later
  </div>
</fly-modal>
